import axios from "@/util/axios";

export default {
    getUserList(data) {
        return axios.fetch({
            method: "post",
            url: "auth/users/user-list",
            data
        });
    },
    exportUserList(data) {
        const queryString = new URLSearchParams(data).toString();
        location.href = process.env.VUE_APP_API_HOST+"exports/user-list?"+queryString
    },
}
